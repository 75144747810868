<template>
  <div id="adminsCard" class="card card-custom grid-stack-item-content">
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label">{{ $t("menu.partnerAccountUsers") }}</h3>
      </div>
      <div class="card-toolbar">
        <button
          class="btn btn-primary btn-hover"
          @click="$router.push({ name: 'partnerAccountUsersCreate' })"
        >
          <i class="fal fa-plus"></i>
          {{ $t("partner.partnerCreate") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <div class="card-body column">
      <TableWrapper :meta="meta" @reload-data="loadPartners">
        <b-table
          responsive
          :items="partners"
          :fields="fields"
          class="dataTable table table-head-custom"
          :filter="searchTitle"
          :show-empty="true"
          :empty-text="$t('table.noRecords')"
        >
          <template #cell(actions)="data">
            <b
              class="btn btn-icon btn-light btn-sm mr-1"
              @click="showDetails(data.item.id)"
            >
              <i class="fal fa-pen"></i>
            </b>
            <b
              class="btn btn-icon btn-light btn-sm"
              @click="deletePartner(data.item.id, data.item.name)"
            >
              <i class="fal fa-trash"></i>
            </b>
          </template>
          <template #cell(name)="data">
            <div class="row flex-wrap py-3">
              <div class="col-auto symbol symbol-50 py-0">
                <div
                  class="symbol-label symbol-label-profile"
                  :style="
                    data.item.profile_picture !== null &&
                    data.item.profile_picture.length !== 0
                      ? 'background-image: url(data:' +
                        data.item.profile_picture.mimeType +
                        ';base64,' +
                        data.item.profile_picture.base64 +
                        '); background-color: #ffffff;'
                      : ''
                  "
                ></div>
              </div>
              <div class="col-auto flex-grow-1 py-0">
                <span class="d-block">{{ data.item.name }}</span>
                <span class="text-muted">{{ data.item.email }}</span>
              </div>
            </div>
          </template>
          <template #cell(active)="data">
            <span
              v-if="data.item.active"
              class="label label-lg label-light-success label-inline"
            >
              {{ $t("general.active") }}
            </span>
            <span v-else class="label label-lg label-light-danger label-inline">
              {{ $t("general.inactive") }}
            </span>
          </template>
        </b-table>
      </TableWrapper>
      <!---->
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { formatDate } from "@/components/Tools/modifiers";
import Users from "@/components/Tenants/Users/users";
import Media from "@/components/Admins/Media/media";
import store from "@/core/services/store";
import TableWrapper from "@/components/Tools/TableWrapper";

export default {
  name: "PartnersAccountPartnerTable",
  // eslint-disable-next-line vue/no-unused-components
  components: { TableWrapper },
  data() {
    return {
      partners: [],
      searchTitle: "",
      fields: [
        {
          key: "id",
          label: this.$t("table.id"),
          sortable: true
        },
        {
          key: "name",
          label: this.$t("partner.user"),
          sortable: true
        },
        {
          key: "active",
          label: this.$t("table.status"),
          sortable: true
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          sortable: false,
          thStyle: { width: "170px" }
        }
      ],
      // Meta info
      meta: [],
      page: 1,
      totalPages: 10,
      pageSize: 10,
      pageSizes: [10, 20, 50, 100],
      totalRecords: 0,
      isBusy: false
    };
  },
  mounted() {
    this.loadPartners();
    this.$root.$on("partnersTableRefresh", () => {
      this.loadPartners();
    });
  },
  methods: {
    getRequestParams(page, pageSize) {
      let params = {};
      if (page) params["page"] = page;
      if (pageSize) params["size"] = pageSize;
      return params;
    },
    setMeta(meta) {
      this.meta = meta;
      this.totalPages = meta.last_page;
      this.totalRecords = meta.total;
    },
    loadPartners() {
      this.isBusy = true;

      const params = this.getRequestParams(this.page, this.pageSize);
      const apiKey = store.getters.apiTokenPartner;

      const headers = {
        headers: {
          "x-api-key": apiKey
        }
      };

      Users.getAll(params, headers)
        .then(response => {
          this.partners = response.data.data;
          this.setMeta(response.data.meta);
          this.isBusy = false;
        })
        .catch(e => {
          console.log(e);
          this.isBusy = false;
        });
    },
    handlePageChange(value) {
      this.page = value;
      this.loadPartners();
    },
    handlePageSizeChange(size) {
      this.pageSize = size;
      this.page = 1;
      this.loadPartners();
    },
    formatDateAssigned(value) {
      return formatDate(value);
    },
    showDetails(id) {
      this.$router.push({
        name: "partnerAccountUsersDetails",
        params: { id: id }
      });
    },
    deletePartner(id, name) {
      let self = this;
      Swal.fire({
        title: this.$t("partner.partnerDeleteTitle"),
        text: this.$t("partner.partnerDeleteMessage", { user: name }),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("general.cancel"),
        confirmButtonText: this.$t("general.delete")
      }).then(result => {
        if (result.isConfirmed) {
          let partnerData = [];
          const apiKey = store.getters.apiTokenPartner;
          const headers = {
            headers: {
              "x-api-key": apiKey
            }
          };
          Users.get(id, headers).then(response => {
            partnerData = response.data.data;
          });

          if (partnerData) {
            Users.delete(id)
              .then(() => {
                if (partnerData.profile_picture !== "") {
                  Media.delete(partnerData.profile_picture);
                }
                this.$toast.fire({
                  icon: "success",
                  title: this.$t("users.userDeleted")
                });
                if (self.page !== 1) {
                  self.handlePageChange(self.page - 1);
                } else {
                  self.loadPartners();
                }
              })
              .catch(error => {
                this.$error(error);
              });
          }
        }
      });
    }
  }
};
</script>
