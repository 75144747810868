<template>
  <div>
    <!--begin::Partners-->
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <Partners></Partners>
      </div>
    </div>
    <!--end::Partners-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Partners from "@/components/Partners/Account/Partner/Table";

export default {
  name: "partnersAccountPartnerIndex",
  components: {
    Partners
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("menu.adminAccountUsers"),
        route: ""
      },
      {
        title: this.$t("general.overview"),
        route: "/partner/users"
      }
    ]);
  }
};
</script>
